<template>
  <div>
    <carousel
      data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000"
      id="home"
      :items="1"
      :autoplay="true"
      :dots="false"
      :autoplayTimeout="10000"
      :autoplaySpeed="true"
      @changed="changed"
      class="home-slider owl-theme"
    >
      <div 
      class="home-item"
      v-for="images in homeCarousel" :key="images.id">
        <img
          v-lazy="images.background_image !== null ? images.background_image.data.full_url : ''" 
        />
        <div class="d-table">
          <div class="d-table-cell">
            <div class="container" v-if="animation">
              <div class="slider-content">
                <span class="animated fadeInRight" v-if="images.title">{{images.title}}</span>
                <h2 class="animated fadeInRight" v-if="images.description">{{images.description}}</h2>
                <div class="slider-btn">
                  <a :href="images.button1_url" v-if="images.button1_url"
                     :target="images.button1_new_window ? '_blank' : '_parent'"
                     class="default-btn animated fadeInDown">
                    {{images.button1_text}}
                  </a>
                  <a :href="images.button2_url" v-if="images.button2_url"
                     :target="images.button2_new_window ? '_blank' : '_parent'"
                     class="optional-btn animated fadeInDown">
                    {{images.button2_text}}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="shape">
          <img src="../assets/img/slider/shape.png" width="90" height="70" alt="image">
        </div>
      </div>
    </carousel>
  </div>
</template>

<script>
  import carousel from 'vue-owl-carousel';

  export default {
    name: "MainBannerArea",
    props: ['homeCarousel'],
    data() {
      return {
        animation: true,
        volume: true
      }
    },
    components: {
      carousel
    },
    async mounted() {
      let prev       = document.getElementsByClassName('owl-prev')[0];
      let next       = document.getElementsByClassName('owl-next')[0];
      if (prev && next) {
        prev.innerHTML = '<i class="flaticon-curve-arrow"></i>';
        next.innerHTML = '<i class="flaticon-curve-arrow-1"></i>';
      }
    },
    methods: {
      changed() {
        this.animation = false;
        setTimeout(() => {
          this.animation = true;
        }, 0)
      }
    }
  }
</script>

<style scoped lang="scss">
  #home {
    margin-top: 85px;
  }

  .main-nav-slider {
    height: 600px;
  }

  .home-item {
    background: #383539;
    position: relative;
    img {
      position: absolute;
      object-fit: cover;
      height: 100%;
      width: 100%;
      object-position: 0 25%;
    }
  }
  .home-slider-one {
    position: relative;
    overflow: hidden;

    .main-nav-slider {
      max-width: 100%;
    }

    &:after {
      content: "";
      position: absolute;
      top: -100px;
      left: -100px;
      right: -100px;
      bottom: -100px;
    }

    img {
      position: relative;
      z-index: 1;
    }

    .slider-text {
      position: absolute;
      z-index: 2;
      top: 60%;
      max-width: 800px;
      white-space: pre-wrap;
      transform: translateY(-50%);
    }
    h2 {
      font-size: 50px;
      color: #fff;
      margin: 0 0 0 100px;
    }
  }
  .home-slider .home-item .slider-content h2 {
    white-space: pre-wrap;
  }
  .video-bg-image {
    margin-top: 129px;
    height: 700px;
    width: 100%;
    object-fit: cover;
  }
  .video-wrapper {
    height: 700px;
    background-color: #1a1b1e;
    margin-top: 129px;
    position: relative;

    .play-video-btn {
      position: absolute;
      bottom: 20px;
      right: 20px;
      width: 35px;
      height: 35px;
      color: #fff;
      font-size: 20px;
      border: 1px solid #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      opacity: 0;
      visibility: hidden;
      transition: 0.5s;
    }

    &:hover .play-video-btn {
      opacity: 1;
      visibility: visible;
    }

    video#myVideo {
      width: 100%;
      height: 100%;
      margin: 0;
      object-fit: cover;
      object-position: 50% 0;
    }

    .video-text {
      position: absolute;
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);
      left: 10%;

      h2 {
        color: #fff;
        width: 450px;
        font-size: 40px;
        user-select: none;
      }
    }
  }

  @media (max-width: 1340px) {
    .video-wrapper {
      height: auto;

      video#myVideo {
        height: auto;
      }
    }
  }

  @media (max-width: 991px) {
    .home-slider .home-item {
      height: 500px;
      padding: 0;
    }
    .home-slider-one {
      padding-top: 76px;

      .slider-text h2 {
        margin-left: 20px;
        margin-right: 20px;
        font-size: 35px;
      }
    }
    .video-wrapper {
      margin-top: 77px;
    }
  }
  @media (max-width: 767px) {
    .main-nav-slider {
      background-position: center;
    }

    .home-slider .home-item .slider-content h2 {
      font-size: 34px;
    }
    .home-slider .home-item {
      height: 400px;
      padding: 0;
    }

    .main-nav-slider {
      height: 400px;
    }

    .home-slider-one {

      .slider-text {
        top:50%;
      }
    }

    .video-wrapper .video-text h2 {
      font-size: 30px;
      width: 300px;
    }
  }

  @media (max-width: 500px) {
    .video-wrapper .video-text h2 {
      font-size: 20px;
      width: auto;
    }
  }
</style>
